<script>
	import { data } from '../stores';
	import { fade } from 'svelte/transition';
	import { onMount } from 'svelte';
	import { getDisplay } from '../utils/helper';
	import { _, locale } from 'svelte-i18n';
	import tippy from 'sveltejs-tippy';
	import MailIcon from './icon/MailIcon.svelte';
	import PhoneIcon from './icon/PhoneIcon.svelte';
	import { DEFAULT_MANAGED_LOGO } from '../constants';

	export let compact = false;

	onMount(() => {
		localStorage.setItem('currentWizardStage', 'recoveryprovider');
	});

	$: preferred = $data.profile?.accounts?.find((i) => i.preferred);
	$: recoveries = $data.profile?.accounts?.filter((i) => i.recovery && !i.preferred);

	const delay = 50;

	const displayString = (account) => {
		if (!account) return '';
		let string = '';
		if (account.slug === 'ethereum') {
			string += account.wallet?.name;
		} else if (account.slug === 'email' || account.slug === 'phone') {
			// Do nothing
		} else {
			string += getDisplay(account.slug);
		}
		if (account.slug === 'email') {
			string += account.user_name;
		} else if (account.slug === 'phone') {
			string += window.intlTelInputUtils.formatNumber(
				account.user_name,
				null,
				window.intlTelInputUtils.numberFormat.INTERNATIONAL
			);
		} else if (account.user_name) {
			if (account.mastodonServer) {
				string += ` (@${account.user_name}@${account.mastodonServer})`;
			} else {
				string += ` (${account.user_name})`;
			}
		}
		return string;
	};
</script>

<ul class="max-w-md mx-auto {compact ? 'my-4 space-y-2' : 'my-8 space-y-4'}">
	{#if preferred}
		<li
			data-test="wizard-progress-preferred-container"
			in:fade={{ delay: delay * 0 }}
			class="p-3 w-full rounded-md bg-charcoal bg-opacity-80 text-white dark:text-[#D4D4D4] {$locale &&
			$locale.startsWith('ar')
				? 'text-right'
				: 'text-left'}"
		>
			<label for="preferred-provider" class="font-bold text-lg">
				{$_('Current Preferred Provider')}
			</label>
			<div class="flex items-center w-full my-1 pl-4 truncate gap-x-2">
				{#if preferred?.slug === 'ethereum'}
					<img
						src={preferred?.wallet.icon}
						alt={preferred?.wallet.name}
						class="w-4.5 max-h-[18px]"
					/>
				{:else if preferred?.slug === 'email'}
					<MailIcon />
				{:else if preferred?.slug === 'phone'}
					<PhoneIcon />
				{:else if preferred?.mastodonServer}
					<img
						src="https://{preferred?.mastodonServer}/favicon.ico"
						alt="Mastodon"
						class="w-4.5 max-h-[18px]"
					/>
				{:else}
					<img
						src="https://cdn.hello.coop/images/{preferred?.slug}.svg"
						alt={preferred?.slug}
						class="w-4.5 max-h-[18px]"
					/>
				{/if}
				<div id="preferred-provider" class="truncate ml-2">
					{#if preferred?.slug === 'ethereum'}
						<span use:tippy={{ content: preferred?.login_hint, placement: 'top' }}>
							{displayString(preferred)}
						</span>
					{:else}
						<span>
							{displayString(preferred)}
						</span>
					{/if}
				</div>
			</div>
			{#if preferred?.slug === 'email'}
				<span class="text-sm my-2 italic font-light block">
					{$_('Your preferred login is email with verification code')}
				</span>
			{:else if preferred?.slug === 'phone'}
				<span class="text-sm my-2 italic font-light block">
					{$_('Your preferred login is phone with verification code')}
				</span>
			{/if}
		</li>
	{/if}

	{#if !compact}
		<li
			in:fade={{ delay: delay * 1 }}
			class="p-3 italic w-full rounded-md text-base md:text-xl text-center font-medium"
		>
			{$_(
				'If you lose access to your preferred provider you can recover by logging in with 2 recovery providers'
			)}
		</li>
	{/if}

	<li
		data-test="wizard-progress-recovery-container"
		in:fade={{ delay: delay * 2 }}
		class="p-3 w-full rounded-md bg-[#DCDCDC] dark:bg-[#505050] text-charcoal dark:text-[#D4D4D4] {$locale &&
		$locale.startsWith('ar')
			? 'text-right'
			: 'text-left'}"
	>
		<label for="recovery-providers" class="font-bold text-lg">{$_('Recovery Providers')}</label>
		<div id="recovery-providers" class="my-3 space-y-3">
			{#each recoveries as recovery}
				<div class="flex items-center w-full my-1 pl-4 truncate gap-x-2">
					{#if recovery.slug === 'email'}
						<MailIcon />
					{:else if recovery.slug === 'phone'}
						<PhoneIcon />
					{:else if recovery.slug === 'ethereum'}
						<img
							src={recovery.wallet.icon}
							alt={recovery.wallet.name}
							class="w-4.5 max-h-[18px] object-contain"
						/>
					{:else if recovery.mastodonServer}
						<img
							src="https://{recovery.mastodonServer}/favicon.ico"
							alt="Mastodon"
							class="w-4.5 max-h-[18px] object-contain"
						/>
					{:else if recovery.managed}
						<span class="managed-rounded-square-sm">
							<img
								src={recovery.logo || DEFAULT_MANAGED_LOGO}
								alt={recovery.slug}
								class="w-4.5 max-h-[18px] object-contain"
							/>
						</span>
					{:else}
						<img
							src="https://cdn.hello.coop/images/{recovery.slug}.svg"
							alt={recovery.slug}
							class="w-4.5 max-h-[18px] object-contain {['apple', 'twitter'].includes(recovery.slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
								? 'invert dark:invert-20'
								: ''}"
						/>
					{/if}
					<div class="truncate" class:ml-2={!recovery.managed}>
						{#if recovery.slug === 'ethereum'}
							<span use:tippy={{ content: recovery.login_hint, placement: 'top' }}>
								{displayString(recovery)}
							</span>
						{:else}
							<span>
								{displayString(recovery)}
							</span>
						{/if}
					</div>
				</div>
			{/each}

			{#if !recoveries.length}
				<div class="font-medium italic ml-4">{$_('You have no recovery providers')}</div>
			{:else if recoveries.length === 1}
				<div class="font-medium italic ml-4">
					{$_('You must have 1 more to recover your Hellō Wallet')}
				</div>
			{/if}
		</div>
	</li>
</ul>
