<script>
	import { _ } from 'svelte-i18n';
	import FullPageModal from './FullPageModal.svelte';

	export let close = () => {};
</script>

<FullPageModal on:close={close} dataTest="profile-landing-modal">
	<span class="mt-2 font-semibold text-lg">{$_('This is your Hellō Wallet')}</span>

	<p class="text-center mt-6">
		{$_('Here is where you can change your login providers and wallet information')}
	</p>

	<p class="text-center mt-6 font-semibold">wallet.hello.coop</p>

	<!-- svelte-ignore a11y-autofocus -->
	<button
		autofocus
		data-test="profile-landing-modal-ok-btn"
		on:click={close}
		class="btn-background w-full mt-6">{$_('OK')}</button
	>
</FullPageModal>
