<script>
	import { _, locale } from 'svelte-i18n';
	import { data as profileData } from '../../stores.js';
	import { dedupe } from '../../utils/helper';
	import RemoveIconButton from '../button/RemoveIconButton.svelte';
	import { getDisplay } from '../../utils/helper';
	import Modal from '../modal/Modal.svelte';
	import RemoveButton from '../button/RemoveButton.svelte';
	import { slide } from 'svelte/transition';
	import LoginProvider from '../LoginProvider.svelte';
	import dayjs from 'dayjs';
	import relativeTime from 'dayjs/plugin/relativeTime';
	import preParsePostFormat from 'dayjs/plugin/preParsePostFormat';
	import 'dayjs/locale/ar';
	import 'dayjs/locale/fr';
	import 'dayjs/locale/es';
	import 'dayjs/locale/hi';
	import 'dayjs/locale/de';
	import Dropdown from '../Dropdown.svelte';
	import logins from '../../../../../svr/providers/logins.json';
	import SpinnerIcon from '../icon/SpinnerIcon.svelte';
	import HandlePictureModal from '../modal/HandlePictureModal.svelte';
	import { deletePicture, postPicture } from '../../utils/api-calls';

	dayjs.extend(preParsePostFormat);
	dayjs.extend(relativeTime);

	export let expanded = false;
	export let editMode = false;
	export let continueWithProvider = () => {};
	export let handleDropdown = () => {};

	let confirmPictureDelete;

	function tryRemove(value) {
		if (value.includes('default-picture.png')) {
			return;
		}

		confirmPictureDelete = value;
	}

	let removePictureAjax = false;
	async function removePicture(index) {
		try {
			removePictureAjax = confirmPictureDelete;
			const { pictures } = await await deletePicture(index);
			$profileData.profile.pictures = pictures;
			confirmPictureDelete = null;
		} catch (err) {
			console.error(err);
		} finally {
			removePictureAjax = null;
		}
	}

	let uploadedPicture = null;
	let handlePictureAjax = false;
	let uploadPictureAjax = false;
	// Picture upload start
	async function uploadPicture(picture) {
		handlePictureAjax = true;
		const formDataObj = new FormData();
		formDataObj.append('file', picture);

		try {
			const { pictures } = await postPicture(formDataObj);
			$profileData.profile.pictures = pictures;
			expanded = false;
			// animateScroll.scrollToTop({
			// 	container: 'main'
			// })
		} catch (err) {
			console.error(err);
		} finally {
			uploadPictureAjax = false;
			handlePictureAjax = false;
		}
	}
	// Picture upload end
</script>

{#if uploadedPicture}
	<HandlePictureModal
		imgSrc={uploadedPicture}
		bind:ajaxRequest={handlePictureAjax}
		on:cancel={() => (uploadedPicture = null)}
		on:upload={(e) => {
			uploadPicture(e.detail);
			uploadedPicture = null;
		}}
	/>
{/if}

<section id="pictures" class="py-2 mt-3">
	<h2 class="font-medium text-lg">{$_('Pictures')}</h2>
	<ul class="flex flex-col space-y-4 mt-2">
		{#if $profileData.profile.pictures?.length}
			{#each dedupe($profileData.profile.pictures, 'picture') as { label, picture, source, slug, createdAt, updatedAt }, index}
				<li class="relative flex-shrink-0 flex items-center">
					<!-- animate:flip={{ duration: flip_animation_ms }}
                    transition:slide|local -->
					<div class="w-6 inline-flex items-center flex-shrink-0">
						{#if editMode && source !== 'default'}
							<RemoveIconButton on:click={() => tryRemove(picture)} />
						{/if}
					</div>
					<div class="flex items-center gap-x-2">
						<img
							src={picture}
							alt={source === 'default'
								? $_('Generic Hellō Profile Picture')
								: source === 'upload'
								? $_('Uploaded from device')
								: $_('From {provider}', {
										values: {
											provider: getDisplay(slug)
										}
								  })}
							class="h-12 w-12 rounded-full object-cover hide-alt-text flex-shrink-0"
							title={source === 'default'
								? $_('Generic Hellō Profile Picture')
								: source === 'upload'
								? $_('Uploaded from device')
								: $_('From {provider}', {
										values: {
											provider: getDisplay(slug)
										}
								  })}
						/>
						<span>
							{#if source === 'default'}
								{$_('Generic Hellō Profile Picture')}
							{:else if source === 'upload'}
								{#if Date.now() - Date.parse(createdAt) < 24 * 60 * 60 * 1000}
									{$_('Uploaded today')}
								{:else}
									{$_('Uploaded {date}', {
										values: {
											date: dayjs(createdAt).locale($locale).fromNow()
										}
									})}
								{/if}
							{:else}
								{$_('From {provider}', {
									values: {
										provider: source || getDisplay(slug)
									}
								})}

								{#if ['gravatar', 'twitter', 'github', 'gitlab', 'instagram'].includes(slug) && label}
									({label})
								{/if}

								{#if slug !== 'gravatar'}
									({Date.now() - Date.parse(updatedAt || createdAt) < 24 * 60 * 60 * 1000
										? updatedAt
											? $_('Updated today')
											: $_('Added today')
										: $_(updatedAt ? 'Updated {date}' : 'Added {date}', {
												values: {
													date: dayjs(updatedAt || createdAt)
														.locale($locale)
														.fromNow()
												}
										  })})
								{/if}
							{/if}
						</span>
					</div>

					{#if confirmPictureDelete && confirmPictureDelete === picture}
						<Modal
							position="left"
							class="md:min-w-[22rem] top-10"
							on:close={() => (confirmPictureDelete = null)}
						>
							<div class="flex flex-col md:flex-row items-center justify-between">
								<span>{$_('Confirm Deletion')}</span>
								<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
									<button
										on:click={() => (confirmPictureDelete = null)}
										class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
									>
									<RemoveButton
										on:click={() => removePicture(index)}
										isLoading={removePictureAjax === picture}
										value="Delete"
									/>
								</div>
							</div>
						</Modal>
					{/if}
				</li>
			{/each}
		{/if}
	</ul>
</section>
{#if !editMode}
	<div
		class="relative flex items-center {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'} p-0.5"
		transition:slide|local
	>
		<Dropdown
			hasBackground={false}
			ariaLabel={$profileData.profile.pictures.filter((i) => !i.default).length
				? $_('Add another profile picture')
				: $_('Add a profile picture')}
			dataTest="add-profile-picture-btn"
			{expanded}
			on:click={() => handleDropdown('picture')}
		>
			<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
				{$profileData.profile.pictures.filter((i) => !i.default).length
					? $_('Add another profile picture')
					: $_('Add a profile picture')}
			</div>

			{#if expanded}
				<div
					class="rounded-md text-left w-full z-30 p-4 space-y-2 flex flex-col"
					transition:slide|local
				>
					<!-- Picture upload start -->
					<button
						on:click={() => {
							uploadPictureAjax = true;
							document.getElementById('picture-upload-ref').click();
							setTimeout(() => {
								uploadPictureAjax = false;
							}, 2022);
							/**
							 * TODO
							 * This is a hack to prevent closing of picture dropdown,
							 * clickOutside is fired because of input file window?
							 */
							setTimeout(() => {
								expanded = true;
							}, 1);
						}}
						disabled={uploadPictureAjax}
						class="disabled:opacity-60 w-full relative btn-background overflow-hidden flex items-center {uploadPictureAjax
							? 'justify-center'
							: 'justify-start'} px-4"
					>
						<div class="w-full flex items-center justify-center">
							{#if uploadPictureAjax}
								<SpinnerIcon css="h-5 w-5 text-white dark:text-[#d4d4d4]" />
							{:else}
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4.5"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
									/>
								</svg>

								<span
									class="block text-left {$locale && $locale.startsWith('ar') ? 'mr-4' : 'ml-4'}"
								>
									{$_('Upload from device')}
								</span>

								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="flex-shrink-0 {$locale && $locale.startsWith('ar')
										? 'mr-auto rotate-90'
										: 'ml-auto -rotate-90'} h-4.5 opacity-80 transform text-white dark:text-[#d4d4d4] group-hover:stroke-3"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
								</svg>
							{/if}

							<input
								id="picture-upload-ref"
								type="file"
								accept="image/*"
								name="logo"
								hidden
								on:change={(e) => (uploadedPicture = URL.createObjectURL(e.target.files[0]))}
								on:cancel={() => (uploadPictureAjax = false)}
							/>
						</div>
					</button>
					<!-- Picture upload end -->

					{#each logins.filter((i) => i.claims.picture) as provider, index}
						<LoginProvider
							prefix="Get picture from"
							on:click={(e) => continueWithProvider(provider, 'picture', e.detail)}
							{provider}
						/>
					{/each}
				</div>
			{/if}
		</Dropdown>
	</div>
{/if}
