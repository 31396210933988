<script>
	import { _, locale } from 'svelte-i18n';
	import { flip } from 'svelte/animate';
	import { slide, fly } from 'svelte/transition';
	import { data as profileData } from '../../stores.js';
	import HelpButton from '../button/HelpButton.svelte';
	import Modal from '../modal/Modal.svelte';
	import RemoveButton from '../button/RemoveButton.svelte';
	import RemoveIconButton from '../button/RemoveIconButton.svelte';
	import PromoteIcon from '../icon/PromoteIcon.svelte';
	import DemoteIcon from '../icon/DemoteIcon.svelte';
	import { dedupe, getDisplay } from '../../utils/helper';
	import * as sort from '../../utils/sort';
	import { DEFAULT_MANAGED_LOGO } from '../../constants';
	import ManagedProviderDropdown from '../ManagedProviderDropdown.svelte';
	import tippy from 'sveltejs-tippy';

	export let editMode = false;
	export let dropdownStates = {};
	export let confirmProviderDelete;
	export let removeProviderAjax = false;
	export let removeUnverifiedProviderAjax = false;
	export let removeProvider = () => {};
	export let removeUnverifiedProvider = () => {};
	export let makePreferred = () => {};
	export let makeRecovery = () => {};
	export let removeRecovery = () => {};
	export let handleDropdown = () => {};
	export let verifyEmailSuccess = () => {};

	const flip_animation_ms = 2000;
</script>

<table id="managed-providers" class="mt-10 table-fixed w-full">
	<thead>
		<tr class="relative">
			<th class="w-1/2 md:w-3/5 text-left text-lg font-medium">
				<div class="flex items-center justify-between">
					<div class="flex items-center">
						<span>{$_('Managed Providers')}</span>
						<HelpButton
							ariaLabel="Help"
							content={$_('Accounts managed by your employer, school, or other organization')}
							placement="top"
						/>
					</div>
				</div>
			</th>
		</tr>
	</thead>
</table>

<div class="mt-2 w-full space-y-2">
	<!-- Checking doneWizardAt here because we don't want to sort accounts in wizard flow -->
	{#if $profileData.profile.accounts.length && $profileData.profile?.actions?.doneWizardAt}
		{#each dedupe($profileData.profile.accounts, 'id')
			.filter((i) => i.managed)
			.sort(sort.accounts) as account, index (account.id)}
			{@const recoveryCount = $profileData.profile.accounts.filter(
				(i) => !i.preferred && i.recovery
			).length}
			<div
				data-test="profile-provider-{index}"
				class="relative flex items-center justify-between"
				animate:flip={{ duration: flip_animation_ms }}
				transition:slide|local
			>
				<!-- (account.verifiedAt check) Delete verified vs unverified -->
				{#if confirmProviderDelete && (account.verifiedAt ? confirmProviderDelete === account.id : confirmProviderDelete === account.user_name)}
					<Modal
						position="left"
						class="md:min-w-[22rem] top-10"
						on:close={() => (confirmProviderDelete = null)}
					>
						<div class="flex flex-col md:flex-row items-center justify-between">
							<span>{$_('Confirm Deletion')}</span>
							<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
								<button
									on:click={() => (confirmProviderDelete = null)}
									class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
								>
								<RemoveButton
									dataTest="remove-provider-{index}-btn-confirm"
									on:click={() => {
										account.verifiedAt
											? removeProvider(account.id)
											: removeUnverifiedProvider(account.user_name);
									}}
									isLoading={account.verifiedAt
										? removeProviderAjax === account.id
										: removeUnverifiedProviderAjax === account.user_name}
									value="Delete"
								/>
							</div>
						</div>
					</Modal>
				{/if}

				<div class="flex truncate items-start md:items-center w-full">
					<div
						class="w-6 flex-shrink-0 py-3 md:py-0 inline-flex items-center justify-start mt-1 md:mt-0"
					>
						{#if editMode}
							<RemoveIconButton
								dataTest="remove-provider-{index}-btn"
								on:click={() =>
									account.verifiedAt
										? (confirmProviderDelete = account.id)
										: (confirmProviderDelete = account.user_name)}
							/>
						{/if}
					</div>
					<div
						class="px-4 relative truncate flex items-start md:items-center w-full py-1 bg-charcoal bg-opacity-10 dark:bg-opacity-100 rounded-md"
					>
						<span class="managed-rounded-square-sm">
							<img
								src={account.logo || DEFAULT_MANAGED_LOGO}
								alt={account.user_name}
								class="w-4.5 max-h-[18px] object-contain"
							/>
						</span>
						<div class="w-full truncate flex flex-col md:flex-row py-1 md:py-2 px-0.5 ml-2">
							<span data-test="profile-provider-{index}-label" class="flex-1 truncate font-medium">
								{account.user_name} ({getDisplay(account.slug)})
							</span>
							<div
								class="gap-x-2 flex justify-start items-center {$locale && $locale.startsWith('ar')
									? 'mr-0 md:mr-3'
									: 'ml-0 md:ml-3'} mt-1 md:mt-0"
							>
								{#if !account.preferred && account.recovery && account.verifiedAt && !account.linkedAccount}
									{#if recoveryCount <= 3}
										<button
											use:tippy={{
												content: $_('You cannot demote your only {count} recovery provider(s).', {
													values: { count: recoveryCount }
												}),
												placement: 'top'
											}}
											data-test="demote-{index}-btn"
											transition:fly|local={{ x: 10 }}
											class="btn-border h-4.5 w-4.5 cursor-not-allowed relative flex items-center justify-center overflow-hidden text-xs opacity-50"
										>
											<DemoteIcon />
										</button>
									{:else}
										<button
											data-test="demote-{index}-btn"
											transition:fly|local={{ x: 10 }}
											on:click={() => removeRecovery(account)}
											class="btn-border h-4.5 w-4.5"
											disabled={recoveryCount <= 3}
										>
											<DemoteIcon />
										</button>
									{/if}
								{/if}

								{#if account.recovery}
									<span
										transition:fly|local={{ x: 10 }}
										class="inline-block text-sm font-bold uppercase truncate
                                    {$locale && $locale.startsWith('ar')
											? 'text-right md:text-left'
											: 'text-left md:text-right'} flex-shrink-0"
									>
										{$_('Recovery')}
									</span>
								{/if}

								{#if account.recovery}
									<button
										use:tippy={{
											content: $_('Managed providers cannot be upgraded to preferred.'),
											placement: 'top'
										}}
										data-test="demote-{index}-btn"
										transition:fly|local={{ x: 10 }}
										class="btn-border h-4.5 w-4.5 relative flex items-center justify-center overflow-hidden text-xs opacity-50 cursor-not-allowed font-medium"
									>
										?
									</button>
								{:else}
									<button
										data-test="promote-{index}-btn"
										on:click={() =>
											account.recovery ? makePreferred(account) : makeRecovery(account)}
										class="btn-border h-4.5 w-4.5"
									>
										<PromoteIcon />
									</button>
								{/if}
							</div>
						</div>
					</div>
				</div>
			</div>
		{:else}
			{#if editMode}
				<span
					class="text-fallback text-sm mt-2 {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'}"
					>{$_('No managed providers')}</span
				>
			{/if}
		{/each}
	{/if}
</div>

{#if !editMode}
	<section
		class="space-y-2 mt-2 {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'} p-0.5"
		transition:slide|local
	>
		<div class="relative flex items-center">
			<ManagedProviderDropdown
				label={$_('Link a managed provider')}
				bind:expanded={dropdownStates.managedProvider}
				on:managedEmailSuccess={verifyEmailSuccess}
				{handleDropdown}
				showIcon={false}
			/>
		</div>
	</section>
{/if}
