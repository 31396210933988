<script>
	import { _ } from 'svelte-i18n';
	import ChooseManaged from '../../../lib/ChooseManaged.svelte';
	import Notification from '../../../lib/Notification.svelte';
	import TitleBar from '../../../lib/TitleBar.svelte';
	import { notification } from '../../../stores';
	import { getManagedLogos, getProfile } from '../../../utils/api-calls';
	import { onMount } from 'svelte';
	import { data as profileData, showSpinner } from '../../../stores';
	import { logPlausibleEvent } from '../../../utils/helper';
	import { replace as replaceRoute } from 'svelte-spa-router';

	const states = {
		PICK_IMAGE: false
	};

	let managedLogos = null;

	onMount(async () => {
		$showSpinner = true;

		//we dont have profile profileData
		if (!$profileData?.version) {
			try {
				$profileData = await getProfile();
			} catch {
				return replaceRoute('/login');
			}
		}

		if ($profileData.chooseManagedLogo) {
			try {
				managedLogos = await getManagedLogos($profileData.chooseManagedLogo.id);
				states.PICK_IMAGE = true;
			} catch (err) {
				console.error(err);
				$notification = {
					text: $_('Something went wrong. Please try again later.'),
					type: 'error'
				};
			}
		}

		if (!$profileData.chooseManagedLogo && !$profileData.chooseWhoManages) return replaceRoute('/');

		logPlausibleEvent({ u: '/wizard/managed' });

		$showSpinner = false;
	});
</script>

<TitleBar showMenu={false} />

{#if $notification.text}
	<Notification />
{/if}

{#if !$showSpinner}
	<div class="px-outer max-w-[856px] w-full mx-auto">
		<main
			class="py-4 stack:py-[5vh] [@media(min-height:1216px)]:!py-16 flex flex-col items-center gap-x-col"
		>
			<div class="max-w-col w-full px-indent mt-2">
				<ChooseManaged {states} {managedLogos} />
			</div>
		</main>
	</div>
{/if}
