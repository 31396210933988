<script>
	import { _ } from 'svelte-i18n';
	import { slide, fade } from 'svelte/transition';
	import { replace as replaceRoute } from 'svelte-spa-router';
	import TitleBar from '../../../lib/TitleBar.svelte';
	import { showSpinner } from '../../../stores.js';
	import VerifyEmail from '../../../lib/VerifyEmail.svelte';
	import MailIcon from '../../../lib/icon/MailIcon.svelte';
	import { data, notification } from '../../../stores.js';
	import Dropdown from '../../../lib/Dropdown.svelte';
	import { getProfile } from '../../../utils/api-calls.js';
	import LoginProviderGroup from '../../../lib/LoginProviderGroup.svelte';
	import { onMount } from 'svelte';
	import Notification from '../../../lib/Notification.svelte';
	import { clearLocalAndSessionStorage, setAttributes } from '../../../utils/helper.js';

	let continueWithEmail = false;

	onMount(async () => {
		$showSpinner = true;

		//we dont have profile data
		if (!$data?.version) {
			try {
				$data = await getProfile();
				if (!$data.isPersonalLoggedIn && !$data.isManagedLoggedIn) {
					clearLocalAndSessionStorage();
					return replaceRoute('/login');
				}
				if ($data.profile?.actions?.doneWizardAt) return replaceRoute('/');
			} catch {
				return replaceRoute('/login');
			}
		}

		if ($data.preferred?.length) return replaceRoute('/wizard/status');

		localStorage.setItem('currentWizardStage', 'preferredprovider');
		$showSpinner = false;
	});

	async function verifyEmailSuccess() {
		try {
			continueWithEmail = false;
			$data = await getProfile();
		} catch (err) {
			console.error(err);
		}
	}

	const delay = 50;
</script>

<TitleBar />

{#if $notification.text}
	<Notification />
{/if}

{#if !$showSpinner}
	<main class="flex-1 px-3 md:px-0 overflow-y-auto pb-16">
		<div>
			<h1
				class="md:hidden mb-2 border-none text-base text-center md:text-2xl block font-medium"
				in:fade={{ delay: 4 * delay }}
			>
				{$_('Select your preferred login provider')}
			</h1>
			<div class="max-w-md md:max-w-2xl mt-6 mx-auto flex items-center justify-center text-sm">
				<h1
					class="hidden md:block text-center border-none text-base md:text-2xl w-96 font-medium"
					in:fade={{ delay: 4 * delay }}
				>
					{$_('Select your preferred login provider')}
				</h1>
			</div>
			<div class="md:max-w-2xl container mx-auto mt-2 text-center">
				<section class="mt-6 max-w-md mx-auto" in:fade={{ delay: 5 * delay }}>
					<LoginProviderGroup
						login={true}
						cryptoWallets={false}
						prefix="Continue with"
						showManagedLogin={false}
					>
						<Dropdown
							dataTest="add-email-btn"
							expanded={continueWithEmail}
							ariaLabel={$_('Continue with Email')}
							on:click={() => {
								continueWithEmail = !continueWithEmail;
							}}
						>
							<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
								<MailIcon />
								<span class="block text-left" aria-hidden="true">
									{$_('Continue with Email')}
								</span>
							</div>
							{#if continueWithEmail}
								<div class="px-3 pb-3 pt-1" transition:slide|local>
									<VerifyEmail on:success={verifyEmailSuccess} />
								</div>
							{/if}
						</Dropdown>
					</LoginProviderGroup>
				</section>
			</div>
		</div>
	</main>

	<wc-footer use:setAttributes />
{/if}
