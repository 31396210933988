<script>
	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import { _ } from 'svelte-i18n';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import TitleBar from '../../../lib/TitleBar.svelte';
	import { data, notification, showSpinner } from '../../../stores.js';
	import { getProfile, postWizard, keepAlive } from '../../../utils/api-calls.js';
	import WizardProgress from '../../../lib/WizardProgress.svelte';
	import Notification from '../../../lib/Notification.svelte';
	import {
		logPlausibleEvent,
		clearLocalAndSessionStorage,
		setAttributes
	} from '../../../utils/helper.js';
	import PointerIcon from '../../../lib/icon/PointerIcon.svelte';
	import InfoIcon from '../../../lib/icon/InfoIcon.svelte';
	import SpinnerIcon from '../../../lib/icon/SpinnerIcon.svelte';

	let nextAjax = false;

	onMount(async () => {
		$showSpinner = true;

		//we dont have profile data
		if (!$data?.version) {
			try {
				$data = await getProfile();
				if (!$data.isPersonalLoggedIn && !$data?.isManagedLoggedIn) {
					clearLocalAndSessionStorage();
					return replaceRoute('/login');
				}

				if ($data.profile?.actions?.doneWizardAt) return replaceRoute('/');

				//we have 2 recoveries
				if (
					$data.profile?.accounts?.filter((i) => i.recovery && !i.preferred && !i.communal)
						.length >= 2
				)
					return replaceRoute('/wizard/status');
			} catch {
				return replaceRoute('/login');
			}
		}

		localStorage.setItem('currentWizardStage', 'incomplete');
		logPlausibleEvent({ u: '/wizard/incomplete' });

		$showSpinner = false;
	});

	async function next() {
		nextAjax = true;
		if (!$data.profile.actions?.doneWizardAt) {
			try {
				await postWizard();
				//End of Wizard Funnel
				sessionStorage.removeItem('wiz_funnel');
			} catch (err) {
				nextAjax = false;
				console.error(err);
			}
		}
		try {
			$data = await getProfile();
			localStorage.setItem('showProfileLandingModal', true);
			localStorage.removeItem('currentWizardStage');
			$notification = {
				text: '',
				type: ''
			};
			navigateTo('/');
		} catch (err) {
			nextAjax = false;
			console.error(err);
		}
	}

	const delay = 50;

	const handleEnterKey = (e) => {
		if (e.key === 'Enter') {
			next();
		}
	};
</script>

<TitleBar />

{#if $notification.text}
	<Notification />
{/if}

<svelte:window on:keydown={handleEnterKey} />

{#if $data?.isPersonalLoggedIn}
	<main class="flex-1 overflow-y-auto pb-16">
		<div class="mt-8">
			{#if $data.preferred?.length}
				<div
					class="max-w-2xl mx-auto px-3 flex items-center justify-between space-x-2 md:space-x-0 text-sm"
					in:fade={{ delay: 0 * delay }}
				>
					<h1 class="text-center flex-1 border-none text-xl font-medium">
						{$_("This is how you'll be logging in")}
					</h1>
				</div>
			{/if}
			<div class="px-3 md:px-0 md:max-w-2xl container mx-auto text-center">
				<WizardProgress />
				<div
					class="-mt-4 opacity-80 max-w-md w-full mx-auto flex items-center text-sm gap-x-2"
					in:fade={{ delay: 5 * delay }}
				>
					<InfoIcon />
					<span class="text-left">{$_('You can change your providers at wallet.hello.coop')}</span>
				</div>
				<h1
					class="md:hidden text-center bg-red-500 mt-6 text-white mx-auto dark:text-white dark:text-opacity-80 px-1 py-2 max-w-md w-full rounded-md border-none text-base md:text-2xl font-medium uppercase"
					in:fade={{ delay: 5 * delay }}
				>
					{@html $_('Recovery Setup<br/>Incomplete')}
				</h1>
				<div
					class="max-w-md md:max-w-2xl mx-auto px-3 flex items-center mt-6 justify-between gap-x-6 md:gap-x-8 text-sm"
					in:fade={{ delay: 5 * delay }}
				>
					<button
						data-test="back-btn"
						on:click={() => {
							keepAlive();
							navigateTo('/wizard/recoveryprovider');
						}}
						class="group w-20 relative inline-flex items-center justify-center"
					>
						<span class="absolute ml-2">{$_('Back')}</span>
						<!-- Back Arrow -->
						<PointerIcon dir="left" />
					</button>
					<h1
						class="hidden md:block text-center bg-red-500 text-white dark:text-white dark:text-opacity-80 px-1 py-2 max-w-md w-full rounded-md border-none text-base md:text-2xl font-medium uppercase"
					>
						{@html $_('Recovery Setup<br/>Incomplete')}
					</h1>
					<button
						data-test="skip-btn"
						disabled={nextAjax}
						on:click={next}
						class="group disabled:opacity-50 disabled:cursor-not-allowed w-20 relative inline-flex items-center justify-center"
					>
						<span class="absolute mr-1">
							{#if nextAjax}
								<SpinnerIcon css="h-5 w-5" />
							{:else}
								{$_('Skip')}
							{/if}
						</span>
						<!-- Next arrow -->
						<PointerIcon dir="right" />
					</button>
				</div>
			</div>
		</div>
	</main>

	<wc-footer use:setAttributes />
{/if}
